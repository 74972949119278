
.react-pagebuilder
    width: 100%
    height: 100vh
    text-align: center
    &__inner
        width: 100%
        height: 100vh
        font-family: $pb-font-primary

        
    

