@import 'styles/sass/abstracts'
  
.input
  width: 100%
  min-height: 88px
  resize: vertical
  border: 1px solid $neutral-1
  border-radius: $radius-md
  background: $neutral-1
  color: $primary-dark
  outline: none
  +typoFormMd
  padding: 15px 16px
  +transition(border-color .2s)
  +placeholder-style
    color: $primary-light

.formTextarea
  // Status
  &--error
    .input
      border-color: $error-default
