// CUSTOMIZE

// Colors
$white: #fff
$black: #000

$primary-dark: #0D3164
$primary-default: #164194
$primary-light: #3F77C1

$accent-dark: #AD184D
$accent-default: #E23B77
$accent-light: #FF84B0

$bio-light: #F5F0E0
$bio-default: #C2C996

$neutral-1: #F2F6F9
$neutral-2: #EAF0F7
$neutral-3: #E1E9F4
$neutral-4: #D3DFF0
$neutral-5: #C3CFE0

$error-dark: #A9192A
$error-default: #DC2E43
$error-light: #F8B1BA

$success-dark: #18966A
$success-default: #22C993
$success-light: #74DCC4

$warning-dark: #D3952D
$warning-default: #FFBB38
$warning-light: #FFE691

$facebook: #3B5998    

// Fonts

$font-primary: 'Lato', sans-serif
$font-secondary: 'Lora', serif
$font-tertiary: 'SmartKid', sans-serif

// $fw-light: 300 // usato?
$fw-regular: 400 // Lato 
$fw-semibold: 600 // Lato
$fw-semibold-secondary: 500 // Lora
$fw-bold: 700 // Lato, Lora
$fw-black: 900 // Lato

// Borders

$radius-xs: 6px
$radius-sm: 10px
$radius-md: 24px
$radius-lg: 42px

// Shadows

$shadow-sm:  2px 2px 6px rgba($primary-dark, 0.1)
$shadow-md:  2px 4px 8px rgba($primary-dark, 0.15)
$shadow-lg:  5px 9px 20px rgba($primary-dark, 0.1)

// Gradients

$gradient-light: linear-gradient(180deg, $primary-default 0%, $primary-light 100%)
$gradient-light-diagonal: linear-gradient(45deg, $primary-default 0%, $primary-light 100%)
$gradient-bio: linear-gradient(45deg, #D0D7B1 0%, rgba(#DEE0AC, .5) 100%)

// Breakpoints

$res-xs-max: 575px
$res-sm-min: 576px
$res-sm-max: 767px
$res-md-min: 768px
$res-md-max: 991px
$res-lg-min: 992px
$res-lg-max: 1199px
$res-xl-min: 1200px
$res-max: 1920px // massima risoluzione supportata (per vincoli immagini hero)

  
// Containers 

$space-unit: 4px
$wcont-width-md: 1170px
$wcont-width-sm: 976px
$wcont-width-xs: 784px
$wcont-width-xxs: 400px
$wcont-padding: 16px
 

// Layout

$toolbar-height: 40px

$header-top-height: 44px
$header-main-height: 66px
$header-height: 110px // somma delle 2 precedenti - non collassa nulla

$header-banner-height-mobile: 56px
$header-main-height-mobile: 60px
$header-search-height-mobile: 60px
$header-height-mobile: 176px // somma delle 3 precedenti
$header-height-mobile-collapsed: 60px // solo header-main, le altre 2 collassano