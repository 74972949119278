@import styles/sass/abstracts

.prodottiCorrelati
  background: $gradient-light-diagonal
  border-radius: 20px
  padding: 50px 30px
  +res-sm-max
    padding: 30px $wcont-padding 20px 
    margin: 0 #{$wcont-padding * -1}
    border-radius: 0
  \:global(.swiper-container)
    padding-bottom: 44px
    +res-sm-max
      margin: 0px #{$wcont-padding * -1}
      padding: 0 $wcont-padding 44px // paginazione bottom
      
.content
  text-align: center
  color: $white
  margin-bottom: 30px
  +res-sm-max
    margin-bottom: 10px

.title
  +typoH2Serif

.description
  +typoTextMd
  +customContentWhite
  margin-top: 10px
  +res-sm-max
    display: none
  

 
