@import styles/sass/abstracts

.toolbar
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  padding: 0 2rem
  width: 100%
  height: $toolbar-height
  background: #fff
  z-index: 7000
  box-shadow: $shadow-md
.adminLink
  margin-right: 20px
.button
  padding-top: 4px!important
  padding-bottom: 4px!important
.user
  +typoTextSm
  font-weight: 700
  a
    +typoLink
  &--impersonate
    font-weight: 400
    span
      font-weight: 700
.right
  margin-left: auto
  display: flex
  > *
    &:not(:last-child)
      margin-right: 20px
@media print
  .toolbar
    display: none
