@import "../../styles/sass/abstracts"

.block-video
    position: relative
    width: 100%
    padding-bottom: 56.25%
    border-radius: 12px
    overflow: hidden
    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

