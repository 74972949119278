@import styles/sass/abstracts

.listingProdottiHead
  position: relative
  overflow: hidden
  padding: 91px 0 76px
  z-index: 1
  +res-sm-max
    padding: 64px 0 48px
  .shape
    position: absolute
    top: -2500px
    bottom: 0
    left: -9999px
    right: -9999px
    width: 4111px
    border-radius: 50%
    margin: 0 auto
    z-index: -1
    overflow: hidden
    background: rgba(#ff0000, .5)
    +res-sm-max
      width: 300%
      top: -1000px
    &::after
      content: ""
      position: absolute
      left: 0
      right: 0
      bottom: 0
      margin: 0 auto
      width: 100vw
      top: 2500px
      background: $gradient-light-diagonal
      +res-sm-max
        top: 1000px



.content
  text-align: center
  color: $white
  > * 
    &:not(:last-child)
      margin-bottom: 30px
      +res-sm-max
        margin-bottom: 20px

.title
  +typoBase(60px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 40px

.description
  +typoTextLg
  +customContent