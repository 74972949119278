@import styles/sass/abstracts

.impegnoSection1
  position: relative
  margin-top: -85px
  color: $primary-default
  +maxResBounds
  +res-lg-min
    margin-top: -170px

.imageBox
  +res-md-min
    position: absolute
    top: 0
    left: 0
    height: 100%
    right: 0
    z-index: 0
  +res-sm-max
    position: relative
    height: 329px
  > *
    height: 100%!important
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

.content
  +res-md-min
    position: relative
    z-index: 1
  +res-lg-min
    padding: 240px 0 100px //184px 0 100px
    width: 60%
  +res-md-only
    padding-top: 100px
  +res-sm-max
    padding: 30px 0 

.title
  +typoH1Serif
  margin-bottom: 30px
  strike
    font-weight: $fw-semibold-secondary
    text-decoration: line-through
  +res-md-only
    width: 60%
  +res-sm-max
    +typoH2Serif
    margin-bottom: 10px

.description
  +typoTextLg
  +customContent  
  +res-md-only
    width: 60%
  +res-sm-max
    +typoTextMd

.slider
  margin-top: 40px
  \:global(.swiper-container)
    padding-bottom: 38px // Paginazione
    +res-md-min
      overflow: visible
    +res-sm-max
      margin: #{$wcont-padding * -1} 0
      padding-left: 0 $wcont-padding
      padding-right: 0 $wcont-padding
  \:global(.swiper-slide)
    height: auto!important
    display: flex
    flex-direction: column
  +res-sm-max
    margin-top: 30px

.item
  padding: 16px
  border-radius: 16px
  background: $white
  box-shadow: $shadow-lg
  text-align: center
  flex: 1
  display: flex
  align-items: center

.itemContent
  flex: 1
  > *
    &:not(:last-child)
      margin-bottom: 8px
  
.itemTitle
  +typoTextLg
  font-weight: $fw-black

.itemDescription
  +typoTextSm
  +customContent

