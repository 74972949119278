@mixin typoBase($size, $lineHeight, $letterSpacing: null, $weight: null, $family: null)
  @if $family
    font-family: $family
  @if $weight
    font-weight: $weight
  font-size: $size
  line-height: $lineHeight
  @if $letterSpacing
    letter-spacing: $letterSpacing

// CUSTOMIZE

@mixin typoH1
  +typoBase(48px, 110px, .01em, $fw-black)

@mixin typoH2
  +typoBase(36px, 43px, .01em, $fw-black)

@mixin typoH3
  +typoBase(32px, 38px, .01em, $fw-black)

@mixin typoH4
  +typoBase(24px, 29px, .01em, $fw-black)

@mixin typoH5
  +typoBase(20px, 24px, .01em, $fw-black)

@mixin typoH1Serif
  +typoBase(36px, 46px, .01em, $fw-bold, $font-secondary)

@mixin typoH1SerifMobile
  +typoBase(28px, 36px, .01em, $fw-bold, $font-secondary)

@mixin typoH2Serif
  +typoBase(24px, 31px, .01em, $fw-bold, $font-secondary)

@mixin typoH3Serif
  +typoBase(20px, 26px, .01em, $fw-bold, $font-secondary)

@mixin typoTextXxs
  +typoBase(10px, 12px, .01em)

@mixin typoTextXs
  +typoBase(12px, 14px, .01em) 

@mixin typoTextSm
  +typoBase(14px, 19px, .01em)

@mixin typoTextMd
  +typoBase(16px, 22px, .01em)

@mixin typoTextLg
  +typoBase(18px, 22px, .01em)

@mixin typoButtonSm
  +typoBase(14px, 20px, .01em, $fw-semibold)

@mixin typoButtonMd
  +typoBase(16px, 24px, .01em, $fw-semibold)

@mixin typoLabelMd
  +typoBase(12px, 20px, .01em, $fw-black)
  text-transform: uppercase

@mixin typoFormMd
  +typoBase(16px, 20px, .01em, null, $font-primary)

@mixin typoLink
  color: $accent-default
  text-decoration: underline

@mixin typoLinkWhite
  color: $white
  text-decoration: underline
