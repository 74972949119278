@import 'styles/sass/abstracts'
  
.thankYouPage
  text-align: center
  padding: 170px 0
  +res-sm-max
    padding: 105px 0

.content
  max-width: 560px
  margin-left: auto
  margin-right: auto
.icon
  svg
    display: block
    +style-icon(120px, $primary-default)
    margin: 0 auto 20px
    +res-sm-max
      +size-icon(80px)
.title
  +typoH3Serif
  margin-bottom: 20px
  +res-sm-max
    +typoH2Serif
.text
  +typoTextMd
  +res-sm-max
    +typoTextSm
.children
  +typoTextMd
  margin-top: 20px
  +res-sm-max
    +typoTextSm
.buttons
  margin-top: 20px
  display: flex
  align-items: center
  justify-content: center
  > *
    &:not(:last-child)
      margin-right: 24px