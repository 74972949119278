@import 'styles/sass/abstracts'
  
.stepBar
  display: flex

.dot
  display: block
  margin: 0 auto
  width: 32px
  height: 32px
  +border-radius(50%)
  border-width: 1px 
  border-style: solid
  +typoTextMd
  line-height: 30px // altezza del dot - bordo
  text-align: center

.label
  display: block
  margin: 8px auto 0
  color: $primary-default
  +typoTextMd
  text-align: center

.item
  flex: 1 0 0
  position: relative
  display: block
  margin: 0 auto

  &:not(:first-child)::before, &:not(:last-child)::after
    content: ""
    position: absolute
    top: 16px // Metà dell'altezza del dot
    height: 1px

  &:not(:first-child)::before
    left: 0
    right: calc(50% + 24px) // 24 = metà width del dot (16px) + margine (8px) 

  &:not(:last-child)::after
    right: 0
    left: calc(50% + 24px) // 24 = metà width del dot (16px) + margine (8px) 


  &--past
    &:not(:first-child)::before
      background: $primary-default
    &:not(:last-child)::after
      background: $primary-default
    .dot
      border-color: $primary-default
      color: $primary-default
      padding: 6px
      svg
        +style-icon(18px, $primary-default)
    .label
      color: $primary-default

  &--active
    &:not(:first-child)::before
      background: $primary-default
    &:not(:last-child)::after
      background: #D3DFF0
    .dot
      background: $primary-default
      border-color: $primary-default
      color: $white
    .label
      color: $primary-default
      font-weight: 700

  &--future
    &:not(:first-child)::before
      background: #D3DFF0
    &:not(:last-child)::after
      background: #D3DFF0
    .dot
      border-color: #D3DFF0
      color: #D3DFF0
    .label
      color: #D3DFF0