@import styles/sass/abstracts

.myAccountAccountView
  +res-md-min
    display: flex
    margin: 0 -16px
    > *
      width: 33.33%
      padding: 0 16px
  +res-sm-max
    > *
      &:not(:last-child)
        margin-bottom: 15px
      
