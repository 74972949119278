@import styles/sass/abstracts

.quoteBox
  color: $primary-dark
  padding: 20px
  background: $neutral-1
  border-radius: 12px
  +res-md-min
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 30px
  +res-sm-max
    text-align: center

.imageBox
  width: 100%
  max-width: 287px
  border-radius: 8px
  overflow: hidden
  > *
    display: block!important
  +res-sm-max
    margin: 16px auto 0

.content
  +res-md-min
    flex: 1

.title
  +typoH3Serif
  margin-bottom: 20px

.description
  +typoTextLg
  +customContent
  font-style: italic
  a
    +typoLink
