@import styles/sass/abstracts

.landingProdottoButton
  background: $primary-default
  color: $white
  padding: 8px 16px
  +typoTextSm
  font-weight: $fw-bold
  text-transform: uppercase
  border-radius: 20px
  cursor: pointer
  &--white
    background: $white
    color: $primary-default