@import styles/sass/abstracts

.eventoCard
  background: $neutral-1
  border-radius: 16px
  max-width: 944px
  overflow: hidden
  display: flex
  box-shadow: 5px 9px 20px rgba(13, 49, 100, 0.1)
  +res-sm-max
    flex-direction: column

  +res-md-min
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 20px
  +res-lg-min
    height: 312px


.content
  padding: 20px 50px
 
  +res-md-min
    align-self: center
  +res-sm-max
    padding: 30px 20px
    order: 1

  > *
    &:not(:last-child)
      margin-bottom: 16px

.imageBox
  position: relative
  > *
    height: 100%!important
  +res-sm-max
    height: 257px

.title
  +typoH4
  margin-bottom: 16px

.abstract
  +typoTextMd