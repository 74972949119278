@import styles/sass/abstracts

.landingProdottoHead
  position: relative
  padding-top: 20px
  padding-bottom: 60px
  min-height: 514px
  overflow: hidden
  z-index: 1
  color: $white
  &--bio
    color: $primary-default
    .shape
      background: $gradient-bio
  +res-sm-max
    padding-bottom: 215px
    min-height: auto

.shape
  width: 214%
  top: 0
  position: absolute
  left: -99999px
  right: -99999px
  bottom: 0
  margin: 0 auto
  overflow: hidden
  border-bottom-left-radius: 50%
  border-bottom-right-radius: 50%
  background: $gradient-light-diagonal
  z-index: -1

.imageBox
  position: absolute
  left: 0
  right: 0
  margin: 0 auto
  bottom: 0
  width: 100vw
  height: 514px
  +maxResBounds
  +res-sm-max
    height: 215px
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

.breadcrumbs
  margin-bottom: 88px
  +res-sm-max
    margin-bottom: 30px

.content
  text-align: center
  max-width: 630px
  margin: 0 auto
  > * 
    &:not(:last-child)
      margin-bottom: 30px

.title
  +typoBase(60px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 40px

.description
  +typoTextLg
  +customContent
  margin-top: 16px
  +res-sm-max
    +typoTextMd

.description h3
  font-weight: 400