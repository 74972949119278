@import styles/sass/abstracts

.impegnoSection6
  background: $neutral-1
  color: $primary-default
  text-align: center
  padding: 73px 0
  +res-sm-max
    padding: 30px 0

.title
  +typoBase(32px, 40px, null, $fw-bold, $font-secondary)
  +res-sm-max 
    +typoH2Serif

.description
  +typoTextLg
  +customContent
  margin-top: 20px
  
.content
  max-width: 588px 
  margin: 0 auto

.slider
  margin-top: 50px
  \:global(.swiper-container)
    padding: 0 36px 24px
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
  \:global(.swiper-container)
    padding-bottom: 38px