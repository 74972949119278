// Pagebuilder
.pb-content
  &.active
    height: 80vh
    margin-bottom: 3rem
    position: relative
    > .pagebuilder-placeholder
      border: none
      width: 100%
      height: 100%
  &--xs
    &.active
      @media screen and (min-width: #{$wcont-width-xs + 452px})
        margin-left: -56px
        margin-right: -232px
      @media screen and (max-width: #{$wcont-width-xs + 451px}) and (min-width: #{$wcont-width-xs + 292px})
        margin-left: -146px
        margin-right: -146px
      @media screen and (max-width: #{$wcont-width-xs + 291px}) and (min-width: $wcont-width-xs)
        margin-left: calc(#{$wcont-width-xs * .5 + $wcont-padding} - 50vw)
        margin-right: calc(#{$wcont-width-xs * .5 + $wcont-padding} - 50vw)

.ROOT
  > * 
    &:not(:last-child)
      margin-bottom: 40px
  