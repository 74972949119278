@import styles/sass/abstracts

.imageHead
  position: relative
  padding-top: 20px
  padding-bottom: 60px
  min-height: 450px
  background: $neutral-1
  +res-sm-max
    padding-top: 0
    padding-bottom: 30px
    min-height: auto

.imageBox
  overflow: hidden
  > *
    height: 100%!important
  +res-md-min
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40%
    border-bottom-left-radius: 350px
  +res-sm-max
    height: 200px

.content
  +res-md-min
    padding-right: calc(40vw + #{$wcont-padding})
  @media screen and (min-width: $wcont-width-md)
    padding-right: calc(#{$wcont-width-md * .5} - 10vw)
  +res-sm-max
    padding-top: 20px

.breadcrumbs
  position: relative
  text-align: left
  margin-bottom: 88px
  z-index: 1
  +res-sm-max
    position: absolute
    top: 10px
    left: $wcont-padding
    right: $wcont-padding
    margin-bottom: 188px

.title
  +typoH1Serif
  +res-sm-max
    +typoH1SerifMobile

.description
  +typoTextLg
  +customContent
  margin-top: 16px
  +res-sm-max
    +typoTextMd

.description h3
  font-weight: 400