@import styles/sass/abstracts

.landingProdottoInfo
  text-align: center
  padding: 40px 0 60px
  color: $primary-default
  &--bio
    .itemImageBg
      color: $bio-default
  +res-sm-max
    padding: 30px 0

.content
  margin-bottom: 40px
  +res-sm-max
    margin-bottom: 30px
  > *
    &:not(:last-child)
      margin-bottom: 10px

.intro
  +typoTextMd
  font-weight: $fw-bold
  text-transform: uppercase
  color: $primary-light

.title
  +typoBase(40px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 32px

.list
  \:global(.swiper-container)
    padding-bottom: 40px
    +res-sm-max
      margin: 0 #{$wcont-padding * -1}
      padding: 0 $wcont-padding 40px
  \:global(.swiper-slide)
    &:nth-child(2n)
      .itemImageBg
        +rotate(90deg)

.item
  padding-top: 20px
  max-width: 333px
  margin: 0 auto

.itemImageBox
  position: relative
  max-width: 210px
  margin: 0 auto
  z-index: 1
  margin-bottom: 40px
  +res-md-min
    transition: transform .5s 
    &:hover
      +scale(1.1)

.itemImageBg
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: -1
  color: $primary-light
  svg
    display: block
    +color-icon(currentColor)

.itemTitle
  +typoH5
  letter-spacing: 0

.itemDescription
  +typoTextSm
  margin-top: 10px
  +res-sm-max
    +typoTextMd