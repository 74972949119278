@import styles/sass/abstracts

.listingProdottiSection1
  padding: 35px 0 40px
  +res-md-min
    display: flex
    align-items: center
    > * 
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 20px

.imageBox
  +res-sm-max
    margin: 0 auto 16px
    text-align: center
    max-width: 300px

.content
  color: $primary-default
  +res-sm-max
    text-align: center
  > * 
    &:not(:last-child)
      margin-bottom: 20px

.title
  +typoBase(40px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 32px

.description
  +typoTextSm
  +customContent
