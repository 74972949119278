@import styles/sass/abstracts

.navMobile
  color: $primary-default

.panel
  padding: 10px 16px
  background: $white
  position: absolute
  right: 0
  width: 100%
  height: 100%
  +transition(transform .2s)
  +translateX(100%)
  overflow: auto
  +scrollbar-style
  &--open, &--main
    +translateX(0)
  &--inactive
    overflow: hidden
  &--main
    z-index: 0
  &--secondary
    top: 0
    z-index: 1000
  &--tertiary
    top: 0
    z-index: 2000
    .menu
      display: flex
      flex-wrap: wrap
      margin-left: -8px
      margin-right: -8px
    .menuItemWrap
      width: 50%
      padding-left: 8px
      padding-right: 8px
      &--large
        width: 100%

.back
  display: flex
  align-items: center
  width: 100%
  padding: 16px 12px 16px 0
  margin-bottom: 10px
  text-align: left
  cursor: pointer

.backArrow
  margin-right: 16px
  svg
    +style-icon(20px, currentColor)

.backLabel
  +typoTextMd
  font-weight: $fw-bold
  color: $primary-default
  flex: 1

.finalLink
  display: block
  padding: 18.5px 0
  +typoTextMd
  font-weight: $fw-bold
  text-decoration: underline
  color: $accent-default


/* SLIDER */

.topMenu
  background: $neutral-1
  border-radius: 12px
  padding: 24px 20px
  margin-bottom: 20px
  overflow: hidden

.topMenuTitle
  +typoBase(20px, 22px, null, $fw-bold, $font-secondary)
  text-align: center
  margin-bottom: 16px

.slider
  \:global(.swiper-container)
    margin: 0 -36px // 16 del panel + 20 del topMenu con sfondo grigino
    padding: 0 36px 24px // paginazione bottom

.sliderItem
  width: 100%
  display: flex
  align-items: center
  height: 82px
  padding: 12px 20px
  background: $gradient-light-diagonal
  color: $white
  +typoTextMd
  font-weight: $fw-bold
  border-radius: 8px
  box-sizing: border-box
  cursor: pointer
  text-align: center
  > *
    &:not(:last-child)
      margin-right: 16px

.sliderItemIcon
  svg
    display: block
    width: 40px
    height: 50px
    +color-icon(currentColor)

/* VOCI MENU */

.menu
  > *
    &:not(:last-child)
      margin-bottom: 10px

.key--parto-riparto
  margin-top: 10px

.menuItem
  display: flex
  align-items: center
  background: $neutral-1
  border-radius: $radius-md
  padding: 18px 20px
  +typoTextMd
  font-weight: $fw-bold
  text-align: left
  width: 100%
  cursor: pointer
  &--main
    padding: 14px 16px

.menuItemIcon
  margin-right: 10px
  width: 40px
  height: 40px
  svg
    +style-icon(40px, currentColor)

.menuItemLabel
  flex: 1

.menuItemArrow
  margin-left: auto
  svg
    +style-icon(24px, currentColor)

.menuPlus
  margin-top: 20px
  display: flex
  align-items: center
  > *
    flex: 1
    &:not(:last-child)
      margin-right: 16px

/* ARTICOLI */

.articoliSlider
  margin: 40px -16px 0
  \:global(.swiper-container)
    padding: 0 16px

/* Box */

.boxes
  display: flex
  > *
    margin-top: 10px
    margin-bottom: 10px

/* PRODOTTI */

.prodotti
  display: flex
  flex-wrap: wrap
  margin-left: -5px
  margin-right: -5px

.prodottiItem
  width: 50%
  padding-left: 5px
  padding-right: 5px
  padding-bottom: 15px
  &--final
    align-self: center

.prodottiFinalWrap
  max-width: 200px
  margin: 0 auto

.prodottiFinalLink
  position: relative
  height: 0
  padding-bottom: 100%
  display: block
  background: rgba($accent-light, .1)
  border-radius: 50%
  color: $accent-default
  text-align: center

.prodottiFinalLinkContent
  position: absolute
  top: 50%
  +translateY(-50%)
  left: 0
  right: 0
  padding-top: 5% // compensazione effetto ottico per allineamento centrato verticalmente

.prodottiFinalLinkIcon
  svg
    +style-icon(24px, currentColor)
    margin: 4px auto 0

.prodottiFinalLinkLabel
  +typoTextMd
  font-weight: $fw-bold
