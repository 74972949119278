@import 'styles/sass/abstracts'
  
.input
  width: 100%
  border: 1px solid $neutral-1
  border-radius: $radius-md
  background: $neutral-1
  color: $primary-dark
  outline: none
  +typoFormMd
  padding: 13px 16px
  +transition(border-color .2s)
  +placeholder-style
    color: $primary-light
  &:focus
    border-color: $primary-default

.inputWrap
  position: relative

.toggler
  position: absolute
  cursor: pointer
  display: block
  top: calc(50% - 10px)
  right: 16px
  svg
    display: block
    +style-icon(20px, currentColor)

.formInputPassword
  &--with-toggler
    .input
      padding-right: 52px
        
  // Status
  &--error
    .input
      border-color: $error-default
