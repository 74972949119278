@import 'styles/sass/abstracts'
  
.wContainer
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  
  &--md
    max-width: $wcont-width-md

  &--sm
    max-width: $wcont-width-sm

  &--xs
    max-width: $wcont-width-xs
    
  &--xxs
    max-width: $wcont-width-xxs