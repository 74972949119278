@import styles/sass/abstracts

.impegnoSection3
  background: $primary-default
  padding: 57px 0


.layout
  +res-md-min
    display: flex
    align-items: center
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 16px  

.content
  color: $white
  +res-sm-max
    text-align: center

.logo
  padding: 10px
  background: $white
  border-radius: 8px
  margin-bottom: 21px
  width: 187px 
  +res-sm-max
    margin: 0 auto 30px

.title
  +typoBase(32px, 40px, null, $fw-bold, $font-secondary)
  margin-bottom: 21px
  +res-sm-max
    +typoH2Serif
    margin-bottom: 20px

.description
  +typoTextLg
  +customContent

.quote
  position: relative
  padding: 56px 0
  +res-sm-max
    padding: 50px 0
    margin-top: 20px

.quoteIconOpen, .quoteIconClose
  position: absolute
  width: 150px
  height: 117px
  +color-icon($white)
  z-index: 0
  +res-sm-max
    width: 94px
    height: 73px

.quoteIconOpen
  top: 0
  left: 0
  +res-sm-max
    left: 36px

.quoteIconClose
  bottom: 0
  right: 0
  +res-sm-max
    right: 36px

.quoteContent
  position: relative
  background: $white
  color: $primary-default
  padding: 30px
  border-radius: 20px
  margin: 0 auto
  z-index: 1
  +typoTextMd
  font-style: italic
  text-align: center
  +customContent
  +res-md-min
    width: 80%