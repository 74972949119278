@import "../../styles/sass/abstracts"

.block-separator
  &::before
    content: ""
    border-bottom: 1px solid $neutral-4
    display: block

.ROOT
  &--editing
    // in editing facilita la selezione visto che altrimenti è alto solo 1px
    .block-separator
      padding-bottom: 8px
      &::before
        padding-top: 8px