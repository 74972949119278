@import styles/sass/abstracts

.prodottoBox
  color: $white
  padding: 10px
  background: $gradient-light-diagonal
  border-radius: 12px
  +res-md-min
    display: flex
    align-items: center
    padding-right: 50px
    > *
      &:not(:last-child)
        margin-right: 30px
  +res-sm-max
    text-align: center
    padding-bottom: 30px

.imageBox
  width: 100%
  max-width: 200px
  +res-sm-max
    margin: 0 auto 10px

.content
  +res-md-min
    flex: 1

.title
  +typoH2Serif
  margin-bottom: 10px

.description
  +typoTextSm
  +customContentWhite

.cta
  margin-top: 20px
