@import styles/sass/abstracts

.listingProdottiSection2
  position: relative
  padding: 50px 0 60px
  +res-sm-max
    padding: 40px 0 50px


.content
  color: $primary-default
  text-align: center
  > * 
    &:not(:last-child)
      margin-bottom: 20px

.title
  +typoBase(40px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 32px

.description
  +typoTextSm
  +customContent

.slider
  margin-top: 20px
  +res-sm-max
    margin-top: 16px
  \:global(.swiper-container)
    +res-md-max
      margin: 0 #{$wcont-padding * -1}
      padding: 0 #{$wcont-padding} 24px