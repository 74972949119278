// Utilities

@mixin vertical-align
  position: absolute
  top: 50%
  @include transform(translateY(-50%))

@mixin placeholder-style
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::placeholder
    @content

@mixin color-icon($value)
  color: $value

@mixin size-icon($size)
  width: $size
  height: $size

@mixin style-icon($size, $color)
  display: block
  +size-icon($size)
  +color-icon($color)

@mixin custom-row($gap)
  margin-left: #{$gap * -.5}
  margin-right: #{$gap * -.5}
  > *
    padding-right: #{$gap * .5}
    padding-left: #{$gap * .5}

@mixin multiline-ellipsis($lines, $line-height)
  height: $lines * $line-height // fallback per IE
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: $lines
  -webkit-box-orient: vertical

@mixin image-cover
  overflow: hidden
  > img, > .lazyload-wrapper > img
    display: block
    position: absolute
    top: 50%
    +translateY(-50%)
    left: -999px
    right: -999px
    margin: 0 auto
    max-width: none
    min-width: 100%
    min-height: 100%
    object-fit: cover

@mixin wcontainer
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  max-width: $wcont-width

@mixin scrollbar-style
  &::-webkit-scrollbar
    background: #DDE0E5
    width: 8px

  &::-webkit-scrollbar-thumb
    background: #AEB2B7
    border: 2px solid #DDE0E5
    +border-radius(4px)

@mixin customContent
  p:not(:last-child)
    margin-bottom: 1em
  a
    +typoLink
  ul
    list-style: none
    padding: 0
  li
    position: relative
    list-style: none
    padding-left: 40px
    &:not(:last-child)
      margin-bottom: 1.2em
    &::before
      content: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.2071 6.29289C20.5977 6.68342 20.5977 7.31658 20.2071 7.70711L10.0743 17.84L4.37534 13.2809C3.94408 12.9359 3.87416 12.3066 4.21917 11.8753C4.56418 11.444 5.19347 11.3741 5.62473 11.7191L9.92582 15.16L18.7929 6.29289C19.1835 5.90237 19.8166 5.90237 20.2071 6.29289Z" fill="%23009597"/></svg>')
      position: absolute
      top: 0
      left: 0
      width: 24px
      height: 24px

@mixin customContentWhite
  +customContent
  a
    +typoLinkWhite

@mixin maxResBounds
  max-width: $res-max
  margin-left: auto
  margin-right: auto