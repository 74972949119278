@import styles/sass/abstracts

.linkBox
  padding: 30px
  border-radius: 12px
  +res-md-min
    display: flex
    > *
      &:not(:last-child)
        margin-right: 30px
  +res-sm-max
    text-align: center
  &--icon
    background: $neutral-1
    +res-md-min
      display: flex
  &--light
    border: 1px solid $neutral-4
    .content
      +res-md-min
        > *
          &:not(:last-child)
            margin-bottom: 10px
    .cta
      +res-md-min
        align-self: flex-end
  
.icon
  +res-sm-max
    margin-bottom: 20px
  svg
    +size-icon(120px)

.content
  > *
    &:not(:last-child)
      margin-bottom: 20px
  +res-md-min
    flex: 1
        
.title
  +typoH3Serif
  margin-bottom: 10px
  +res-sm-max
    margin-bottom: 20px

.description
  +typoTextMd
  +customContent
  +res-sm-max
    margin-bottom: 20px
  a
    +typoLink