@import styles/sass/abstracts

.search
  &:not(.search--desktop-open)
    +res-lg-min
      .input
        padding: 10px
        opacity: 0
      .submit
        display: none

.inputWrap
  position: relative
  background: $neutral-1
  border-radius: $radius-md
  +res-md-min
    overflow: hidden

.input
  border: none
  background: transparent
  width: 100%
  padding: 10px 48px 10px 16px
  +typoBase(14px, 20px, null, null, $font-primary) 
  +transition(opacity .2s)
  +placeholder-style
    color: $primary-light
  +res-md-max
    padding-top: 13px
    padding-bottom: 13px
    padding-right: 56px
    font-size: 16px // Per evitare zoom su iPhone

.submit
  position: absolute
  right: 0
  top: 0
  z-index: 1
  cursor: pointer
  padding: 11px 16px
  svg
    +style-icon(24px, currentColor)
  +res-lg-min
    display: none