@import 'styles/sass/abstracts'
  
.input
  width: 100%
  border: 1px solid $neutral-1
  border-radius: $radius-md
  background: $neutral-1
  color: $primary-dark
  outline: none
  +typoFormMd
  padding: 13px 16px
  +transition(border-color .2s)
  +placeholder-style
    color: $primary-light
  &:focus
    border-color: $primary-default

.formInput
  // Status
  &--error
    .input
      border-color: $error-default
