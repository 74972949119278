@import styles/sass/abstracts

.boxHeroListingCard
    position: relative
    .boxHero
      position: relative
      height: 648px
      min-height: 610px
      max-height: 100vh
      +maxResBounds
      > *
        height: 100%!important

    .boxHero__desktop
      +res-sm-max
        display: none
    .boxHero__mobile
      display: none
      +res-sm-max
        height: 610px
        display: block
       
    .content__container
      position: absolute
      top: 107px
      left: 0
      right: 0
      max-width: 1170px
      margin: 0 auto
      padding-left: 16px
      padding-right: 16px

      +res-sm-max
        max-width: 100%
        background-color: #ffffff
        +border-radius(12px)
        padding: 20px
        left: 0
        right: 0
        margin: 0 16px
        top: 34px
        text-align: center
      
      .content
        max-width: 588px

        .title
          +typoBase(60px, 66px, .01em, $fw-bold, $font-secondary)
          padding-bottom: 20px
          color: $primary-default
          +res-sm-max
            +typoH2Serif
            
        .description
          +typoBase(20px, 28px, .01em, null, $font-primary)
          +customContent
          padding-bottom: 20px
          color: $primary-default
          +res-sm-max
            +typoTextMd

        .ctaBox
          display: flex
          flex-wrap: wrap
          +res-sm-max
            justify-content: center
            margin-left: -8px
            margin-right: -8px
          > *
            +res-sm-max
              margin: 0 8px 15px
            &:first-child
              +res-md-min
                margin-right: 20px
            



    \:global(.swiper-container)
      margin: -140px -16px  0 -16px// 16 del panel + 20 del topMenu con sfondo grigino
      padding: 0 36px 24px // paginazione bottom
    
      

.cta
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

