@import styles/sass/abstracts

.impegnoSection4
  background: $primary-light
  color: $white
  padding: 60px 0
  text-align: center
  +res-sm-max
    padding: 50px 0 35px

.title
  +typoH2Serif
  margin-bottom: 18px
  +res-sm-max   
    margin-bottom: 20px

.slider
  \:global(.swiper-container)
    padding-bottom: 38px
  \:global(.swiper-wrapper)
    box-sizing: border-box
  \:global(.swiper-pagination-bullet-active)
    background: $white
  \:global(.swiper-slide)
    opacity: .5
    width: 330px!important
    height: auto!important
    +res-sm-max
      width: 260px!important
  \:global(.swiper-slide.swiper-slide-prev)
    opacity: .8
  \:global(.swiper-slide.swiper-slide-next)
    opacity: .8
  \:global(.swiper-slide.swiper-slide-active)
    opacity: 1

.item
  padding: 20px 
  background: $white
  color: $primary-default
  border-radius: 12px
  height: 100%

.itemIcon
  margin-bottom: 20px
  svg
    display: block
    +size-icon(80px)
    margin: 0 auto

.itemDescription
  +typoTextMd
  +customContent

.results
  margin: 50px auto 0
  padding: 20px
  max-width: 558px
  border-radius: 20px
  background: $white
  color: $primary-dark
  text-align: center
  > *
    &:not(:last-child)
      margin-bottom: 20px

.resultsTitle
  +typoH2Serif

.resultsBox
  max-width: 337px
  margin-left: auto
  margin-right: auto
  padding: 16px 20px
  border-radius: 10px
  background: $gradient-light-diagonal
  color: $white
  display: flex
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 10px

.resultsIcon
  svg
    display: block
    width: 90px
    height: 93px

.resultsContent
  +typoBase(22px, 26px, .01em, $fw-bold)

.resultsDescription
  +typoTextLg
  +customContent