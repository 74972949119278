@import styles/sass/abstracts

.content
  text-align: center
  margin-bottom: 30px
  color: $primary-dark
  > *
    &:not(:last-child)
      margin-bottom: 10px

.title
  +typoH2Serif

.description
  +typoTextMd
  +customContent
  
.articoliSlider
  +res-md-min
    \:global(.swiper-wrapper)
        justify-content: center
    \:global(.swiper-slide)
      margin-right: 5px!important
  \:global(.swiper-container)
    padding-bottom: 24px
    margin: 0 auto
    +res-md-max
      padding-left: $wcont-padding
      padding-right: $wcont-padding
      margin: 0 #{$wcont-padding * -1}
    