@import styles/sass/abstracts

.settimana
  display: flex
  justify-content: center
  align-items: center
  // width: 60px
  // height: 60px
  background: $neutral-1
  color: $primary-default
  padding: 18px 12px
  +typoTextMd
  font-weight: $fw-bold
  border-radius: 16px

  &:hover
    background: $neutral-3

  &--selected
    background: $neutral-3
    border: 2px solid $primary-default
    padding: 16px 10px
