@import styles/sass/abstracts

.settimanaHead
  position: relative
  padding-top: 20px
  padding-bottom: 54px
  background: $neutral-1
  +res-md-min
    min-height: 280px
  +res-sm-max
    padding-top: 17px
    padding-bottom: 30px

.settimana
  position: absolute
  right: 0
  top: 0
  bottom: 0
  width: 100%
  overflow: hidden

.settimanaContent
  position: absolute
  +typoBase(25vw, .7, 0.01em, $fw-bold, $font-secondary)
  color: $primary-default
  opacity: .1
  +res-md-min
    right: -5%
    bottom: -5%
  +res-sm-max
    font-size: 150px
    top: 0
    right: -5%

.content
  position: relative
  +res-md-min
    padding-right: calc(40vw + #{$wcont-padding})
  @media screen and (min-width: $wcont-width-md)
    padding-right: calc(#{$wcont-width-md * .5} - 10vw)
  +res-sm-max
    padding-top: 20px

.breadcrumbs
  position: relative
  z-index: 1
  text-align: left
  margin-bottom: 50px
  +res-sm-max
    margin-bottom: 35px

.title
  +typoH1Serif
  +res-sm-max
    +typoH1SerifMobile

.description
  +typoTextLg
  +customContent
  margin-top: 16px
  +res-sm-max
    +typoTextMd
