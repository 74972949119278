@import styles/sass/abstracts

.hubCard
  background: $white
  border-radius: 20px
  max-width: 944px
  overflow: hidden
  box-shadow: $shadow-md
  +res-md-min
    display: flex
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 20px
  +res-lg-min
    height: 313px
  &--reverse
    .content
      +res-md-min
        order: 1

.content
  padding: 20px 50px
  +res-md-min
    align-self: center
  +res-sm-max
    padding: 30px 20px
  > *
    &:not(:last-child)
      margin-bottom: 12px

.imageBox
  position: relative
  > *
    height: 100%!important
  +res-sm-max
    height: 257px

.iconBox
  background: $neutral-4
  svg
    +style-icon(230px, currentColor)
    margin: 0 auto
  +res-md-min
    align-self: stretch
    display: flex
    align-items: center
  +res-sm-max
    padding: 40px 0
    svg
      +size-icon(180px)
  


.title
  +typoH4
  margin-bottom: 16px

.description
  +typoTextSm
  +customContent
  +res-sm-max
    +typoTextMd