@import styles/sass/abstracts

.myAccountMenu
  +res-md-max
    height: 56px
    
  \:global(.swiper-container)
    width: 100%
    height: 100%

  \:global(.swiper-slide)
    position: relative
    width: auto
    &:not(:last-child)
      +res-sm-max
        margin-right: 17px
        &::after
          content: ""
          position: absolute
          top: calc(50% - 20px)
          right: -8px
          height: 40px
          width: 1px
          background-color: $neutral-4

.list
  display: flex
  flex-direction: column
  min-height: calc(100vh - #{$header-height + 60px}) // 60px = 40px di padding superiore della sidebar + 20px custom di distacco dal fondo
  > *
    &:not(:last-child)
      margin-bottom: 16px
  +res-md-max
    display: none


.blockTitle
  +typoTextSm
  font-weight: $fw-black
  text-transform: uppercase
  color: $primary-light
  margin-bottom: 16px

.logout
  margin-top: auto

.slider
  +res-lg-min
    display: none

.item
  display: block  

.myAccountMenuItem
  padding: 16px
  +border-radius(10px)
  align-items: center
  +res-lg-min
    display: flex
  +res-md-max
    display: inline-flex
  > * 
    &:not(:last-child)
      margin-right: 12px
  &--active
    background-color: $neutral-4
    cursor: default
    .label
      font-weight: 900

.icon, .iconRight
  +style-icon(24px, currentColor)

.label
  +typoTextMd
  font-weight: 600
  
\:global(.toolbar ~ .page)
  .list
    +res-lg-min
      min-height: calc(100vh - #{$header-height + $toolbar-height + 60px})