@import styles/sass/abstracts

.containerForm
  background: $gradient-light
  border-radius: 20px
  padding: 2rem
  text-align: center
  margin-top: 2rem

.title
  +typoH5
  color: $white
  margin-bottom: 20px
  text-align: center

.checkbox
  color: $white
  text-align: left
  font-size: 1.4rem
  line-height: 1.9rem

.submitBox
  color: $white
  width: 100%
  text-align: center
  margin: 0 auto

.invisible
  display: none
