@import styles/sass/abstracts

.heroWithBox
  position: relative
  +maxResBounds
  .imageBox
    height: 670px
    img
     height: 670px!important
  +res-sm-max
    .imageBox
      height: 310px
      img
        height: 310px!important
  .content__container
    top: 32px
    left: 0
    right: 0
    position: absolute
    max-width: 1170px
    margin: 0 auto
    padding-left: 16px
    padding-right: 16px
    +res-sm-max
      position: relative

    .content
      background: #FFFFFF
      border-radius: 20px
      box-shadow: $shadow-md
      padding: 40px 46px
      max-width: 464px
      min-height: 400px
      margin-left: 16px
      +res-sm-max
        padding: 0
        position: relative
        border-radius: 0
        box-shadow: none
        margin: 0 auto
        
    
      .title
        +typoH2Serif
        padding-bottom: 10px
        +res-sm-max
          text-align: center
          +typoH1SerifMobile
      .description
        +typoTextMd
        +customContent
        padding-bottom: 20px
        +res-sm-max
          text-align: center
      .ctaBox
          text-align: center
          padding: 40px 0 20px 0
          +res-sm-max
             padding: 40px 0 
      
        
  &__productContainer
    display: flex
    justify-content: center
    align-items: center
  
  &__productCard
    &:not(:last-child)
      margin-right: 20px

  \:global(.swiper-container)
    background: #ffffff
    +res-sm-max
      padding-bottom: 30px

  \:global(.swiper-pagination)
    bottom: 40px
    +res-sm-max
      bottom: 0
    
