@import styles/sass/abstracts

.heroSlider  
  background: $gradient-light
  +res-md-min
    +border-radius(12px)
    box-shadow: $shadow-md
  +res-sm-max
    text-align: center
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

  \:global(.swiper-container)
    padding-bottom: 36px

  \:global(.swiper-pagination)
    bottom: 28px
     
  \:global(.swiper-pagination-bullet-active)
    background: #FFFFFF

.layout
  +res-md-min
    display: flex
    align-items: center
    justify-content: space-between
    padding: 70px 90px 50px
  +res-sm-max
    padding: 30px
    > *
      &:not(:last-child)
        margin-bottom: 30px

.imageBox
  position: relative
  z-index: 1
  width: 374px
  +res-md-min
    flex: 0 0 374px
  +res-md-only
    flex: 0 0 244px
  +res-sm-max
    width: 244px
    margin: 0 auto
  &::after
    content: ""
    position: absolute
    left: 0
    right: 0
    width: 284px
    height: 284px
    top: calc(50% - 142px)
    margin: 0 auto
    border-radius: 50%
    background: $neutral-1
    z-index: -1
    +res-md-max
      width: 185px
      height: 185px
      top: calc(50% - 92.5px)

.content
  +res-md-min
    order: -1
    max-width: 467px
    margin-right: 20px
  > *
    &:not(:last-child)
      margin-bottom: 20px

.content
  color: #FFFFFF

.title
  +typoH3
  +res-sm-max
    +typoH5

.description
  +typoTextLg
  +customContentWhite
  +res-sm-max
    +typoFormMd
