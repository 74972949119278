.left-sidebar
    width: 39px
    background-color: $pb-grey-90
    border-right: 1px solid $pb-grey-100

    &__tabs
        width: 100%

    &__tab-item
        position: relative
        background-color: $pb-grey-90
        color: $pb-grey-20
        border-bottom: 1px solid $pb-grey-100
        padding: 7px
        cursor: pointer

        &--active
            background-color: $pb-white
            color: $pb-grey-100
            border-right: none
            &::after
                content: ""
                position: absolute
                top: 0
                bottom: 0
                right: -1px
                width: 1px
                background: $pb-white

        svg
            +style-icon(24px, currentColor)

    &__panel
        width: 250px
        height: 100%
        background-color: $pb-white
        border-right: 1px solid $pb-grey-20
        pointer-events: auto
        display: flex
        flex-direction: column
        overflow: auto
        +scrollbar-style
        &--lg
            width: 600px

        &__header
            display: flex
            justify-content: space-between
            align-items: center
            padding: 10px
            height: 38px
            &__close
                cursor: pointer
                svg
                    display: block
                    width: 16px
                    height: 16px
                    stroke: $pb-grey-50

            &__title
                +typoCaptionLg

        &__content
            flex-grow: 1

        &__overlay
            position: absolute
            left: 39px
            top: 35px
            width: calc(100% - 35px - 25%)
            height: calc(100% - 35px)
            z-index: 100000 // indicator di craft.js ha 99999
            pointer-events: none
            display: none
            &--open
                display: block
