@import "../../styles/sass/abstracts"

.ROOT
  &--editing
    .block-prodotto-box
      .empty-prodotto
        padding: 50px
        text-align: center
        background: linear-gradient(45deg, $primary-default 0%, $primary-light 100%)
        color: $white
        font-size: 12px
        &__error
          padding: 10px
          color: $pb-error-dark
          background: $pb-error-light
          display: inline-block
          border-radius: 6px
        &__loader
          svg
            +color-icon(currentColor)
            margin-top: 0
            margin-bottom: 0