@import styles/sass/abstracts

.myAccountDeleteCard
  background: $white
  padding: 30px
  +border-radius(20px)
  +res-sm-max
    padding: 16px
.title
  +typoH5
  margin-bottom: 16px
.description
  +typoTextSm
  margin-bottom: 16px
