@import 'styles/sass/abstracts'
  
.overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 8000
  background-color: rgba($primary-dark, .6)
  @media print
    display: none
  
     
  
.modal
  position: fixed
  top: 50%
  +translateY(-50%)
  left: 16px
  right: 16px
  z-index: 8001
  max-width: 560px
  margin: 0 auto
  +border-radius(20px)
  background: $white
  overflow: hidden
  @media print
    display: none
  &--scrollable
    .wrap
      max-height: calc(100vh - 32px) // 112 = 16*2 (margin) 
      overflow: hidden auto
      +scrollbar-style

.wrap
  padding: 40px
  +res-sm-max
    padding: 30px 20px 20px


.close // Non previsto dalla grafica - fatto a intuito
  position: absolute
  top: 20px
  right: 20px
  cursor: pointer
  +res-sm-max
    top: 12px
    right: 12px
  svg
    width: 16px
    height: 16px
    +color-icon($primary-dark)
.head
  margin-bottom: 40px
.icon
  margin-bottom: 40px
  svg
    display: block
    +size-icon(90px)
    margin: 0 auto
.title
  +typoH4
  +res-sm-max
    +typoH5
.description
  +typoTextSm
  margin-top: 16px
.buttons
  display: flex
  justify-content: center
  margin-top: 40px
  > *
    &:not(:last-child)
      margin-right: 20px