@import styles/sass/abstracts
  
.accordion
  background: $neutral-1
  border-radius: 12px

.trigger
  +typoTextLg
  font-weight: $fw-black
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  cursor: pointer
  text-align: left
  padding: 20px

.arrow
  margin-left: 24px
  svg
    +style-icon(24px, currentColor)

.content
  max-height: 0
  overflow: hidden
  +transition(max-height .3s linear)
  +typoTextMd

.innerContent
  padding: 0 20px 20px
