@import styles/sass/abstracts

.landingProdottoProdotti
  padding: 50px 0 52px
  color: $primary-default
  text-align: center

.content
  max-width: 560px
  margin-left: auto
  margin-right: auto
  margin-bottom: 30px

.intro
  +typoTextMd
  font-weight: $fw-bold
  text-transform: uppercase
  color: $primary-light

.title
  +typoBase(40px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 32px

.description
  margin-top: 30px
  +typoTextSm
  +customContent

.categories
  \:global(.swiper-container)
    display: inline-block
    margin: 0 #{$wcont-padding * -1}    
    padding: 0 $wcont-padding
    width: calc(100% + 32px)
  \:global(.swiper-slide)
    width: auto!important

.category
  margin-top: 20px

.categoryDescription
  max-width: 560px
  margin: 0 auto 20px
  +customContent

.productList
  \:global(.swiper-container)
    margin: 0 #{$wcont-padding * -1}    
    padding: 0 $wcont-padding 38px
    width: calc(100% + 32px)
  \:global(.swiper-slide)
    height: auto!important
    background: $white
    border-radius: 30px

.product 
  position: relative
  padding: 30px 10px
  > *
    &:not(:last-child)
      margin-bottom: 10px

.productImageBox
  max-width: 180px
  margin: 0 auto 10px

.productTitle
  +typoTextMd
  font-weight: $fw-black

.productDescription
  max-width: 560px
  margin-left: auto
  margin-right: auto
  +typoTextSm
  +customContent

.productInfoTrigger, .productInfoTriggerMobile
  position: absolute
  top: 20px
  right: 20px
  z-index: 2
  svg
    +style-icon(20px, currentColor)

.productInfoTrigger
  +res-md-max
    display: none

.productInfoTriggerMobile
  cursor: pointer
  +res-lg-min
    display: none

.productInfoTooltip
  \:global(.tooltip-inner)
    max-width: none

.productInfoBox, .productInfoModalContent
  > *
    &:not(:last-child)
      margin-bottom: 10px

.productInfoBox
  width: 346px

.productInfoItem, .productInfoModalItem
  display: flex
  align-items: center

.productInfoIcon, .productInfoModalIcon
  width: 58px
  height: 58px
  margin-right: 16px

.productInfoLabel, .productInfoModalLabel
  flex: 1
  +typoTextSm
  +customContentWhite
  text-align: left

.productInfoModalContent
  color: $primary-default

.productInfoModalItem
  border: 1px solid $neutral-4
  padding: 10px
  border-radius: 12px