@import styles/sass/abstracts

.topMenu
  display: flex
  justify-content: flex-end
  z-index: 1000
  > *
    &:not(:last-child)
      margin-right: 24px

.label
  +typoTextXs

.item
  position: relative
  display: flex
  align-items: center
  color: $primary-default
  > *
    &:not(:last-child)
      margin-right: 4px
  &--open
    &:not(.item--area-riservata)
      .label
        &::after
          opacity: 1
        span
          opacity: 0
    .dropdown
      display: block

  &--area-riservata
    .label
      font-size: 13px
      line-height: 15.6px
      font-weight: $fw-bold
      padding-bottom: 2px
      border-bottom: 1px solid currentColor
  &:not(.item--area-riservata)
    .label
      // Gestione bold che sposta tutto il menu
      position: relative
      padding: 0 3px
      &::after
        content: attr(data-title)
        font-weight: $fw-bold
        position: absolute
        left: -10px
        right: -10px
        margin: 0 auto
        font-weight: $fw-bold
        opacity: 0
        text-align: center
        top: 50%
        +translateY(-50%)
    &:hover
      .label
        &::after
          opacity: 1
        span
          opacity: 0

.content
  height: $header-top-height
  display: flex
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 4px


.arrow
  svg
    +style-icon(10px, currentColor)

.icon
  svg
    +style-icon(20px, currentColor)

.image
  width: 24px
  height: 24px

.dropdown
  position: absolute
  top: 100%
  left: 0
  width: 203px
  background: $white
  box-shadow: $shadow-md
  display: none
  z-index: 102 // deve superare il 101 della searchbar

.dropdownItem
  +typoTextXs
  font-weight: $fw-black
  padding: 14px
  display: block
  &:hover
    background: $neutral-1
