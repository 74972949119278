@import 'styles/sass/abstracts'
  
.error
  text-align: center
  color: $error-default

.helpers
  +typoTextSm
  display: flex
  align-items: center
  justify-content: space-between
  margin: 4px 0 20px
  a
    font-weight: 600
    color: $accent-default
    
.resendActivation
  display: block
  +typoTextSm
  font-weight: 600
  color: $accent-default
  margin-bottom: 20px

.submit
  width: 100%