@import styles/sass/abstracts

.footer
  color: $primary-default
  background: $white
  +typoTextSm
  padding: 54px 0 40px
  +res-sm-max
    padding: 50px 0 10px

.top
  display: flex
  align-items: center
  margin-bottom: 30px
  +res-sm-max
    flex-wrap: wrap
    margin-bottom: 30px
.bCorp
  margin-bottom: 20px

.logoWrap
  +res-md-min
    margin-right: 75px

.logo
  svg
    width: 116px
    height: 80px
    color: $primary-default

.socialMenu
  display: flex
  align-items: center
  justify-content: space-between
  margin-left: auto
  width: 112px
  +res-md-min
    order: 1
  +res-sm-max
    align-self: flex-end

.socialItem
  svg
    +style-icon(24px, currentColor)

.info
  +res-sm-max
    margin-top: 40px
    width: 100%

.customerService
  +res-md-min
    margin-left: 30px
  +res-sm-max
    margin-top: 40px    
    width: 100%


.customerServiceTitle
  margin-bottom: 4px

.phone
  display: flex
  align-items: center
  font-weight: $fw-black

.phoneIcon
  margin-right: 10px
  svg
    +style-icon(20px, currentColor)

.containerMenuFooter
  display: flex
  justify-content: space-between
  +res-md-max
    flex-direction: column
  .disclaimer
    order: 1
    max-width: 50%
    +res-md-max
      max-width: 100%
      padding-top: 20px
.menu
  +res-md-min
    display: flex
    flex-wrap: wrap
    > *
      &:not(:last-child)
        margin-right: 24px

.menuItem
  display: block
  font-weight: $fw-bold
  padding: 10px 0

.menuSeparator
  flex-basis: 100%