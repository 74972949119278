@import styles/sass/abstracts

.roundHead
  position: relative
  padding-top: 20px
  padding-bottom: 60px
  min-height: 223px
  text-align: center
  +res-sm-max
    padding-bottom: 30px
  &--default
    .shape
      &::after
        background: $neutral-1
  &--white
    .shape
      &::after
        background: $white
  &--lg
    .description
      +typoTextLg
  &--md
    .description
      +typoTextMd

.breadcrumbs
  text-align: left
  margin-bottom: 10px

.icon
  margin-bottom: 30px
  svg
    margin: 0 auto
    +style-icon(90px, $primary-default)

.title
  +typoH1Serif
  +res-sm-max
    +typoH1SerifMobile

.descriptionWrap
  margin-top: 16px

.description
  +customContent

.roundHead
  &--readMore
    .descriptionWrap
      +typoTextLg
      max-height: #{2 * 21.6px}
      +transition(max-height .2s)
      overflow: hidden
      // se metto i ... non funziona l'animazione perchè il contenuto è tagliato dal line-clamp
      // nel caso serve un accrocchio
      // &:not(.descriptionWrap--open)
      //   display: -webkit-box
      //   -webkit-line-clamp: 2
      //   -webkit-box-orient: vertical

.shape
  position: absolute
  bottom: 0
  top: 0
  left: 0
  right: 0
  z-index: -1
  overflow: hidden
  &::after
    content: ""
    position: absolute
    width: 300vw
    height: 300vw
    margin: 0 auto
    bottom: 0
    left: -9999px
    right: -9999px
    border-radius: 50%