@import styles/sass/abstracts

.timeline
  padding: 30px
  +border-radius(16px)
  +box-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))
  +res-sm-max
    padding: 20px

  > *
    &:not(:last-child)
      margin-bottom: 30px
      +res-sm-max
        margin-bottom: 20px
        
.slider
  \:global(.swiper-container)
    +res-sm-max
      margin: 0 -36px
      padding-left: 36px
      padding-right: 36px

  \:global(.swiper-slide)
    width: 60px!important

.title
  text-align: center
  +typoTextLg
  font-weight: $fw-black
  color: $primary-default
  +res-sm-max
    +typoTextMd

.nav
  display: flex
  justify-content: space-between
  align-items: center