.with-wireframe
    outline: 1px dashed $pb-grey-20 !important
    outline-offset: -2px
    
.component-selected
    outline: 2px solid $pb-selected !important
    outline-offset: -1px

.component-hovered
    outline: 2px solid $pb-hovered !important
    outline-offset: -1px

.cursor-move
    cursor: move

.cursor-pointer
    cursor: pointer

.component-menu
    display: flex
    align-items: center
    z-index: 1
    height: 30px
    margin-top: -30px
    position: fixed
    padding: 5px 8px 4px
    outline: none
    background: $pb-black
    color: $pb-white
    +typoTextSm
    font-weight: 600
    border-radius: 4px 4px 0 0
    margin-left: -1px
    &__actions
        margin-left: 10px
        > *
            padding: 0 2.5px 
        svg
            display: inline-block
            width: 16px
            height: 16px
            fill: $pb-white

.component-menu-selected
    background-color: $pb-selected
    outline-color: $pb-selected
.component-menu-hovered
    background-color: $pb-hovered
    outline-color: $pb-hovered
.component-menu-global
    background-color: $pb-global
    outline-color: $pb-global 


.context-menu
    position: absolute
    background-color: $pb-grey-10
    border: 1px solid $pb-grey-50
    border-radius: 2px
    min-width: 100px
    +typoTextSm
    z-index: 999

//.context-menu-items

.context-menu-item
    padding: 2px 10px
    cursor: pointer
    p
        margin: 5px
    &:hover
        background-color: $pb-grey-20

.disabled
    cursor: default
    color: $pb-grey-80

.floating-settings-menu
    min-width: 300px
    position: absolute
    background-color: $pb-grey-10
    border: 1px solid $pb-grey-50
    border-radius: 2px
    pointer-events: auto
    display: flex
    flex-direction: column
    color: black

    &-header
        padding: 10px
        display: flex
        justify-content: space-between
        border-bottom: 1px solid $pb-grey-20
        &-title
            white-space: nowrap
        &-close
            cursor: pointer
        svg
            +style-icon(16px, $pb-grey-80)
            width: 16px
            height: 16px
            stroke: $pb-grey-50

    
    &-footer
        padding: 10px
        border-bottom: 1px solid $pb-grey-20
        text-align: center