@import styles/sass/abstracts

.slider
  &--paginationDesktop
    padding-bottom: 56px
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
  \:global(.swiper-container)
    +res-sm-max
      padding: 0 $wcont-padding 56px
