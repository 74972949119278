@import styles/sass/abstracts

.figlioCard
  +border-radius(20px)
  background-color: $white
  +res-md-min
    padding: 30px
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 30px
  +res-sm-max
    padding: 20px
    text-align: center
  
.icon
  svg
    display: block
    +size-icon(80px)
    +res-sm-max
      margin: 0 auto 16px
.content
  flex: 1
  display: flex
  justify-content: space-between
  +res-md-min
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 30px
  +res-sm-max
    flex-direction: column
    align-self: stretch

.title
  +typoH4
  margin-bottom: 12px
  +res-sm-max
    +typoH5
    margin-bottom: 16px

.data
  +res-md-min
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 20px
        padding-right: 20px
        position: relative
        &::after
          content: ""
          position: absolute
          right: 0
          top: calc(50% - 10px)
          height: 20px
          width: 1px
          background-color: $primary-light
  +res-sm-max
    margin-bottom: 16px
    > *
      display: block
      &:not(:last-child)
        margin-bottom: 16px

.birthdate, .age
  display: inline-flex
  align-items: center
  svg
    +style-icon(24px, currentColor)
    +res-sm-max
      +size-icon(20px)
  > *
    &:not(:last-child)
      margin-right: 8px

.ctaBox
  min-width: 145px
  display: flex
  flex-direction: column
  +res-md-min
    align-self: stretch
    justify-content: space-between
    align-items: flex-end
    > * 
      &:not(:last-child)
        margin-bottom: 8px
  +res-sm-max
    align-items: center
    > * 
      &:not(:last-child)
        margin-bottom: 16px

