.react-select
  &__control
    border: 1px solid $neutral-1!important
    border-radius: $radius-md!important
    background: $neutral-1!important
    color: $primary-dark
    width: 100%
    outline: none!important
    box-shadow: none!important
    min-height: 0!important
    +typoFormMd
    padding: 13px 16px!important
    +transition(border-color .2s)

  &__indicator-separator
    display: none!important

  &__dropdown-indicator
    svg
      +style-icon(16px, $primary-dark)
      
  &__value-container
    padding: 0!important

  &__single-value
    margin: 0!important
    color: $primary-dark!important

  &__menu
    margin: 0!important
    +border-radius($radius-md!important)
    +box-shadow(none!important)
    overflow: hidden

  &__menu-list
    padding: 0!important
    +scrollbar-style

  &__option
    font-size: 16px!important
    line-height: 22px!important
    padding: 13px 16px!important
    color: $primary-dark!important
    cursor: pointer!important
    background: $neutral-1!important
    &--is-selected
      background: $neutral-4!important
      color: $primary-dark!important
    &:hover, &--is-focused:not(.react-select__option--is-selected)
      background: $neutral-2!important
      color: $primary-dark!important
      outline: none!important
  
  // Disabled
  &--is-disabled
    .react-select
      &__single-value
        color: $neutral-4!important
      &__dropdown-indicator
        svg
          +color-icon($neutral-4)

  // Status
  &--error
    .react-select
      &__control
        border-color: $error-default!important
        