@import styles/sass/abstracts

.categorieProdottoCorrelate
  text-align: center

.title
  +typoH2Serif
  margin-bottom: 47px
  +res-sm-max
    margin-bottom: 20px

.list
  \:global(.swiper-container)
    padding: 0 $wcont-padding 28px
    margin: 0 #{$wcont-padding * -1}