@import styles/sass/abstracts

.background
  position: absolute
  top: 0
  left: 0
  right: 40px
  bottom: 0
  +res-sm-max
    right: 30px
    
.legend
  +typoTextSm
  color: $primary-light
  position: absolute
  right: 0
  top: 10px // margin-top del Graph
  bottom: 50px // margin-bottom del Graph
  width: 35px
  +res-sm-max
    font-size: 10px
    line-height: 12px
    width: 24px
    
.legendValue
  position: absolute
  right: 0
  top: 0
  width: 100%        
  margin-top: -10px //metà del line-height, per centrarli verticalmente
  +res-sm-max
    margin-top: -6px

.help
  position: absolute
  top: 50%
  right: 0
  width: 100%
  margin-top: 19px //11 margin-top dei legend-value + 8px margin 
  svg
    +style-icon(24px, $primary-dark)
    margin: 0 auto
    +res-sm-max
      +size-icon(20px)

.content
  position: relative
  padding-right: 40px
  height: 400px
  overflow: hidden
  +res-sm-max
    height: 346px

.actions
  text-align: right
  margin-top: 16px
  padding-right: 12px
  +res-md-min
    display: none

.tooltip
  padding: 12px 16px
  +border-radius(6px)
  background: $primary-dark
  color: $white
  position: relative
  &::after
    position: absolute
    top: 100%
    left: 0
    right: 0
    margin: 0 auto
    content: ""
    width: 11px
    height: 11px
    border: 5.5px solid transparent
    border-top-color: $primary-dark

.tooltipDate
  display: block
  margin-bottom: 8px
  font-family: $font-primary
  font-size: 12px
  font-weight: 400
  line-height: 14px
  letter-spacing: 0.01em

.tooltipValue
  display: block
  +typoTextSm
  font-weight: 900

.graph
  &--mobile-expanded
    .wrap
      overflow: auto hidden
      +scrollbar-style
    .content
      width: 800px
  