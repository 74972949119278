@import "../../styles/sass/abstracts"

.ROOT
  &--editing
    .block-box-list
      padding: 16px 16px 0
      min-height: 60px
      display: flex
      flex-wrap: wrap
      > *
        width: calc(50% - 8px)
        margin-bottom: 16px
        &:nth-child(2n+1)
          margin-right: 16px
          
      &--empty
        position: relative
        &::before
          content: "Trascina qui degli elementi di tipo Attività Box"
          position: absolute
          top: 50%
          +translateY(-50%)
          left: 16px
          right: 16px
          text-align: center
          font-size: 14px
          line-height: 19px
          color: $primary-dark
          