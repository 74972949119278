@import styles/sass/abstracts

.sliderMiglioriProdotti
  padding: 50px $wcont-padding
  color: $white
  background: $gradient-light
  border-radius: 20px
  \:global(.swiper-container)
    margin: 0px auto
    max-width: 960px
    padding: 0 $wcont-padding 38px // paginazione bottom
    +res-md-max
      margin: 0 #{$wcont-padding * -1}
  +res-sm-max
    border-radius: 0

.content
  text-align: center
  margin-bottom: 30px

.title
  +typoH2Serif
  +res-sm-max
    +typoH1SerifMobile

.description
  margin-top: 10px
  +typoTextMd
  +customContentWhite

.ctaBox
  text-align: center
  margin-top: 30px

 
