@import 'styles/sass/abstracts'

.submitBox
  margin-bottom: 70px
  text-align: center
  +res-sm-max
    text-align: center


.modal
  text-align: center

.modalButtons
  +res-sm-max
    display: block
    margin-top: 20px
    > *
      &:not(:last-child)
        margin-right: 0
        margin-bottom: 8px
          
.modalResult
  margin-top: 20px
  +typoH4

.modalIcon
  margin-bottom: 40px
  svg
    display: block
    margin: 0 auto
    +size-icon(90px)