@import styles/sass/abstracts

.head
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  margin-bottom: 16px
.title
  +typoH5
  min-height: 40px
.description
  margin-bottom: 24px
  +res-sm-max
    margin-bottom: 16px
.buttons
  display: flex
  justify-content: flex-end
  > *
    &:not(:last-child)
      margin-right: 20px
