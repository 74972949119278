
.rte-fixed
    .ql-toolbar
        position: fixed
        display: flex
        flex-wrap: wrap
        align-items: center
        left: 39px
        top: 35px
        //height: 35px
        // 
        width: calc(100% - 39px - 220px)
        background-color: white
        z-index: 3
        font-size: 1rem
        line-height: normal
        border-top: 0!important
        border-left: 0!important
        border-right: 0!important

    .ql-editor
        padding: 0 !important
        font-size: inherit !important
        color: inherit !important
        background-color: inherit !important
        text-align: inherit !important
        //white-space: normal !important
        line-height: inherit !important
        overflow-y: visible !important
        > *
            cursor: text !important

    .ql-editor blockquote
        border: none !important
        margin: 0 !important
        padding: 0 !important
        font-size: 2em
        &::before
            content: "“"
            display: block
        &::after
            content: "”"
            display: block

    .ql-container
        font-size: inherit !important
        font-family: inherit !important
        border: 0 !important

    .ql-tooltip
        z-index: 2 !important

.ql-editor
    padding: 0 !important
    font-size: inherit !important
    color: inherit !important
    background-color: inherit !important
    text-align: inherit !important
    //white-space: normal !important
    line-height: inherit !important
    overflow-y: visible !important
    > *
        cursor: inherit !important

.rte-no-toolbar
    .ql-toolbar
        display: none


.block-text
    white-space: pre-wrap !important

.block-button
    white-space: pre-wrap !important