@import 'styles/sass/abstracts'
  
.title
  +typoH5
  margin-bottom: 20px
.buttons
  display: flex
  justify-content: flex-end
  margin-top: 50px
  > *
    &:not(:last-child)
      margin-right: auto
  +res-xs-max
    > *
      flex: 1 0 0
      max-width: calc(50% - 4px)
      &:not(:last-child)
        margin-right: 8px