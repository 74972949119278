@import styles/sass/abstracts

.impegnoSection5
  background: $white
  color: $primary-default
  padding: 50px 0

.layout
  +res-md-min
    display: flex
    align-items: center
    > *
      flex: 1 0 0 
      &:not(:last-child)
        margin-right: 16px
  +res-sm-max
    > *
      &:not(:last-child)
        margin-bottom: 30px

.content
  > *
    &:not(:last-child)
      margin-bottom: 35px
      +res-sm-max
        margin-bottom: 30px
  +res-sm-max
    text-align: center

.title
  +typoBase(32px, 40px, null, $fw-bold, $font-secondary)

.description, .bottom
  +typoTextMd
  +customContent

.imageBox
  overflow: hidden
  +res-md-min
    height: 558px
    border-radius: 20px
    box-shadow: $shadow-lg
  +res-sm-max
    height: 243px
    border-radius: 16px
  > *
    height: 100%!important