.viewport
    height: 100%
    background-color: $pb-grey-20

.content-wrapper
    height: calc(100% - 35px)
    width: 100%
    display: flex

.content
    height: 100%
    width: calc(100% - 259px)

    &__preview
        width: 100%

        .canvas
            padding: 0

.craftjs-renderer
    width: 100%
    height: calc(100% - 35px)
    +scrollbar-style
    overflow: auto
    scroll-behavior: smooth

    &__preview
        height: 100%

.canvas
    display: flex
    justify-content: center
    padding: 90px 16px
    +transition(padding .2s)
