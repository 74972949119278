@import styles/sass/abstracts

.activityCard
  padding: 16px
  border-radius: 8px
  background: $neutral-1

.title
  +typoTextLg
  font-weight: $fw-black
  margin-bottom: 10px

.description
  +typoTextMd
  +customContent

.activityCard
  &:not(.activityCard--editMode)
    .description
      +multiline-ellipsis(2, 22px)
      +res-sm-max
        height: 88px // fallback per IE
        -webkit-line-clamp: 4