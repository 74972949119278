@import styles/sass/abstracts

.brandCard
  padding: 30px
  +border-radius(20px)
  background-color: $white
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 30px
      +res-sm-max
        margin-right: 20px
  +res-sm-max
    padding: 20px
.content
  min-height: 158px
  display: flex
  flex-direction: column
  align-items: flex-start
  +res-sm-max
    min-height: 138px
.title
  margin-left: auto
  margin-right: auto
  +typoH4
  margin-bottom: 16px
  text-align: center
  +res-sm-max
    +typoH5
.description
  margin-left: auto
  margin-right: auto
  +typoTextSm

.brandsContainer
  margin-top: 30px
  width: 100%
  .brandsWrapper
    display: flex
    flex-wrap: wrap
    border: none
    margin: -5px 0 0 -5px
    li
      flex: 1 1 76px
      margin: 5px 0 0 5px
      a
        margin-left: auto
        margin-right: auto
      a:hover
          cursor: pointer
      img
        width: 75.24px
        height: 50px