@import styles/sass/abstracts

.shareButton
  display: flex
  align-items: center
  color: $accent-default
  text-align: left
  &:not(:disabled)
    cursor: pointer
  > *
    &:not(:last-child)
      margin-right: 8px

.label
  +typoTextSm
  font-weight: $fw-bold 

.icon
  svg
    +style-icon(20px, currentColor)

.modal
  max-width: 345px

.title
  color: $primary-dark
  font-weight: $fw-bold
  text-align: center
  margin-bottom: 20px
  margin-top: -10px  

.social
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  gap: 10px
  margin-bottom: 10px

.socialButton
    height: 44px
    background-color: $neutral-2!important
    +border-radius(22px)
    padding-top: 5px!important
    padding-bottom: 5px!important
    flex: 22%
    +res-md-max
        flex: 48%
    svg
        vertical-align: middle

.links
  display: flex
  flex-direction: column

.linksButton
  &:not(:last-child)
    margin-bottom: 10px

.copied
  position: fixed
  bottom: 1.7rem
  right: 3rem
  padding: .8rem
  background: #fff
  font-size: 1.2rem
  line-height: 1.6rem
  border: 1px solid $neutral-4
  z-index: 9999
  +border-radius(.6rem)