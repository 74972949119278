@import styles/sass/abstracts

.myAccountLayout
  background: $neutral-1
  +res-lg-min
    display: flex
    justify-content: flex-end
    padding: 40px 0
  +res-md-max
    padding: 20px $wcont-padding 30px

.sidebar
  +res-lg-min
    max-width: 300px
    min-height: 900px
    border-right: 1px solid $neutral-4
    padding: 0 $wcont-padding
    width: 25%
  +res-xl-min
    padding: 0 40px
  +res-md-max
    margin-bottom: 30px

.main
  +res-lg-min
    padding: 0 16px 0 40px
    flex: 1
    max-width: 75%
  @media screen and (min-width: $wcont-width-md)
    padding-right: calc(50vw - #{$wcont-width-md * .5} + 16px)

.backButton
  margin-bottom: 20px

.title
  +typoH3
  margin-bottom: 20px

.description
  +typoTextSm 
  +customContent
  margin-bottom: 50px
  max-width: 543px
