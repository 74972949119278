@import styles/sass/abstracts

.dashboardCard
  padding: 30px
  +border-radius(20px)
  background-color: $white
  display: flex
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 30px
      +res-sm-max
        margin-right: 20px
  +res-sm-max
    padding: 20px
.icon
  svg
    +style-icon(90px, $primary-dark)
    +res-sm-max
      +size-icon(60px)
.content
  min-height: 158px
  display: flex
  flex-direction: column
  align-items: flex-start
  +res-sm-max
    min-height: 138px
.title
  +typoH4
  margin-bottom: 16px
  +res-sm-max
    +typoH5
.description
  +typoTextMd
  +res-sm-max
    +typoTextSm
.button
  margin-top: auto