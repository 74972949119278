@import styles/sass/abstracts

.overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 8000
  background-color: rgba($primary-dark, .6)
  @media print
    display: none

.modal
  position: fixed
  top: 0%
  left: 0
  right: 0
  z-index: 8001
  border-radius: 0
  background: #000000
  max-width: 100%
  height: 100%
  @media print
    display: none

.videoContainer
  position: relative
  width: 100%
  height: 100%


.video
  position: absolute
  top: 0
  left: 0  
  width: 100%
  height: 100%

.video-container
  &::after
    padding-bottom: 56.25%
    display: block
    content: 0


.close
  top: 12px
  right: 4px
  cursor: pointer
  z-index: 11111111
  position: absolute
  +res-sm-max
    top: 5px

  svg
    width: 22px
    height: 22px
    color: #ffffff