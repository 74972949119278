@import styles/sass/abstracts

.categoriesSlider
  margin-top: 10px
  \:global(.swiper-container)
    padding: 20px $wcont-padding 28px// per shadow
    margin: 0 -$wcont-padding

.categoriesSliderItem
  padding: 30px 30px 20px
  border-radius: 12px
  text-align: center
  background: $white
  color: $primary-default
  box-shadow: $shadow-md

.categoriesSliderItemIcon
  display: block
  width: 60px
  height: 60px
  margin: 0 auto 20px
  svg
    +style-icon(60px, currentColor)

.categoriesSliderItemTitle
  +typoH5
  margin-bottom: 20px
    

.categoriesListItem
  margin-top: 40px

.categoriesListItemTitle
  +typoTextLg
  font-weight: $fw-black
  text-transform: uppercase
  color: $primary-light

.faqListItem
  margin-top: 16px
