@import styles/sass/abstracts

.prodottoCorrelatoCard
  color: $white
  text-align: center

.imageBox
  position: relative
  max-width: 164px
  margin: 0 auto 16px
  z-index: 1
  &::after
    content: ""
    position: absolute
    bottom: -6px
    left: 14px
    right: 14px
    height: 0
    padding-bottom: calc(100% - 28px)
    background: currentColor
    border-radius: 50%
    z-index: -1

.content
  > *
    &:not(:last-child)
      margin-bottom: 10px

.title
  +typoH3Serif

.description
  +typoTextSm