@import styles/sass/abstracts

.footer
  background: $gradient-light
  color: $white
  padding: 80.5px 0 65px
  +res-sm-max
    padding: 50px 0

.layout
  +res-md-min
    display: flex
    justify-content: space-between
    > * 
      max-width: 368px

.title
  +typoH2Serif
  margin-bottom: 20px
  +res-sm-max
    margin-bottom: 30px
    text-align: center

.list
  > *
    &:not(:last-child)
      margin-bottom: 20px
    +res-sm-max
      margin-bottom: 30px
  +res-sm-max
    margin-bottom: 32px

.item
  display: flex
  align-items: center
  +res-sm-max
    padding: 10px 0
  
.icon
  width: 84px
  height: 84px
  padding: 12px
  background: $white
  border-radius: 50%
  margin-right: 30px
  svg
    +style-icon(60px, $primary-dark)

.description
  +typoTextMd
  +customContentWhite

.newsletterTitle
  +typoH5
  margin-bottom: 20px
  +res-sm-max
    display: none

.newsletterSubmit
  width: 100%