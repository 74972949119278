@import styles/sass/abstracts

.impegnoSection2
  background: $white
  color: $primary-default
  padding: 71px 0
  +res-sm-max
    padding: 30px 0 0

.layout
  +res-md-min
    display: flex
    align-items: center
    > *
      flex: 1 0 0

.imageBox
  box-shadow: $shadow-lg
  +res-md-min
    border-radius: 20px
    margin-right: 5%
    overflow: hidden
    height: 558px
  +res-sm-max 
    height: 240px
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
  > *
    height: 100%!important

.content
  +res-md-min
    order: 2
  +res-sm-max
    text-align: center

.head
  +res-md-min
    display: flex
    align-items: center
    margin-bottom: 30px
  +res-sm-max
    margin-bottom: 24px

.icon
  +res-md-min
    margin-right: 20px
  +res-sm-max
    margin-bottom: 10px
  svg
    width: 111px
    height: 82px
    +res-sm-max
      width: 122px
      height: 90px
    
.title
  +typoBase(32px, 40px, null, $fw-bold, $font-secondary)
  +res-sm-max
    +typoH2Serif

.item
  padding-bottom: 20px
  +res-sm-max
    padding-bottom: 24px
  &::before
    content: ""
    display: block
    width: 100%
    max-width: 150px
    border-top: 1px solid $neutral-4
    margin-bottom: 20px
    +res-sm-max
      margin: 0 auto 24px
  +res-md-min
    &:first-child
      &::before
        display: none

.itemTitle
  +typoH3Serif
  margin-bottom: 10px
  +res-sm-max
    margin-bottom: 8px

.itemDescription
  +typoTextMd
  +customContent