@import styles/sass/abstracts

.landingProdottoIngredienti
  position: relative
  padding: 75px 0
  color: $primary-default
  +res-sm-max
    padding: 35px 0 50px

.bg
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  overflow: hidden
  z-index: 0
  +maxResBounds
  background: $primary-light // TODO

.bgImageBox
  height: 100%
  +res-lg-min
    display: none

.bgVideo
  position: absolute
  left: 0
  right: 0
  top: 0
  height: 100%
  object-fit: cover
  +res-md-max
    display: none

.layout
  position: relative
  z-index: 1
  +res-lg-min
    display: flex
    align-items: flex-start
    > *
      &:not(:last-child)
        margin-right: 70px

.content
  color: $primary-dark
  > *
    &:not(:last-child)
      margin-bottom: 10px
  +res-lg-min
    max-width: calc(50% - 35px)
  +res-md-max
    text-align: center

.intro
  +typoTextMd
  font-weight: $fw-bold
  text-transform: uppercase

.title
  +typoBase(40px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 32px

.description
  +typoTextSm
  +customContent

.list
  position: relative
  +res-lg-min
    width: 550px
    height: 550px
    padding: 100px 63px
    border-radius: 50%
    background: $white
    box-shadow: $shadow-lg
  +res-md-max
    margin-top: 71px
    padding: 0 $wcont-padding 20px
    \:global(.swiper-container)
      padding: 0 0 18px
      margin: 0 #{$wcont-padding * -1}
    \:global(.swiper-slide)
      height: auto!important
      background: $white
      box-shadow: $shadow-lg
      border-radius: 20px
      overflow: hidden
  
.item
  text-align: center
  background: $white
  +res-lg-min
    height: 350px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  +res-md-max
    padding: 86px $wcont-padding 29px

.itemTitle
  +typoBase(30px, 1, null, $fw-bold, $font-tertiary)
  +res-sm-max
    font-size: 32px

.itemDescription
  margin-top: 16px
  +res-sm-max
    margin-top: 18px

.itemButtons
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: center
  margin: 16px -15px 0
  > *
    margin: 0 15px

.itemTooltip
  \:global(.tooltip-inner)
    max-width: 400px
    +res-xs-max
      max-width: 300px

.activeIconMobile
  position: absolute
  border-radius: 50%
  padding: 16px
  background: $white
  box-shadow: $shadow-lg
  z-index: 10
  width: 128px
  height: 128px

.activeIconMobile
  position: absolute
  top: -64px
  left: 0
  right: 0
  margin: 0 auto
  +res-lg-min
    display: none

.pageButton
  +res-lg-min
    position: absolute
    border-radius: 50%
    padding: 16px
    background: $white
    box-shadow: $shadow-lg
    z-index: 10
    width: 128px
    height: 128px
    color: $primary-light
    cursor: pointer
    transition: transform .2s ease-in-out, opacity .2s 
    +scale(.7)
    > *
      opacity: .6
    &:nth-child(1)
      top: -25px
      left: 19px
    &:nth-child(2)
      top: 107px
      left: -75px
    &:nth-child(3)
      bottom: 143px
      left: -85px
    &:nth-child(4)
      bottom: -8px
      left: 19px

.pages
  z-index: 1
  +res-md-max
    position: absolute
    bottom: 0
    left: 0
    right: 0
    text-align: center
    \:global(.swiper-pagination-bullet)
      margin: 0 4px
    img 
      display: none
  \:global(.swiper-pagination-bullet-active)
    +res-lg-min
      +scale(1)
      > *
        opacity: 1      
    
.navigationButton
  position: absolute
  top: -24px
  width: 48px
  height: 48px
  padding: 12px
  border-radius: 50%
  background: $accent-default
  color: $white
  cursor: pointer
  z-index: 10
  +res-lg-min
    display: none
  svg
    +style-icon(24px, currentColor)
  &--prev
    left: 36px
  &--next
    right: 36px